

import {Component, Vue} from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import KisiKurumSorgulaInfo from "@/components/infos/KisiSorgulaInfo.vue";
import app from "@/main";
import {AvukatSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/AvukatSorgulama";
import {MDB} from "@/plugins/uyap-plugin/MDB";

@Component({
  components: {KisiKurumSorgulaInfo, SehirPicker, TcKimlikNumarasi}
})
export default class VekilSorgulaForm extends Vue {
  mdb = new MDB();
  formData : any  = {
    tcKimlikNo:"",
    tbbNo:"",
    baroNo:"",
    ilKodu:""
  }

  async onSubmit(){
    try {
     let avukat = await app.$uyap.AvukatSorgulama().recursive(this.formData);
     this.$emit("success",avukat);
    }catch (e) {
      app.$toast.error(e.message);
    }
  }
}
