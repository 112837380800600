
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {KisiSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/KisiSorgulama";

@Component({
  components: {}
})
export default class KisiSorgulaInfo extends Vue {
  @Prop({required: true}) cevap!: KisiSorgulamaCevap;

  headers = [
    {text: "Adı", value: "adi"},
    {text: "Soyadı", value: "soyadi"},
    {text: "Cinsiyet", value: "cinsiyeti"},
    {text: "Ölüm Kaydı Var", value: "olumKaydi"},
    {text: "Avukat Mı", value: "isAvukat"},
    {text: "TC Kimlik No", value: "tcKimlikNo"},
    {text: "Doğum Tarihi", value: "dogumTarihiStr"},
    {text: "Noter Mi", value: "isNoter"},
    {text: "Ana Adı", value: "anaAdi"},
    {text: "Baba Adı", value: "babaAdi"},
    {text: "Hakim Mi", value: "isHakimSavci"},
  ]

  get kisiAd() {
    if (this.cevap && this.cevap.adi && this.cevap.soyadi)
      return this.cevap.adi + ' ' + this.cevap.soyadi;
    else
      return "";
  }
}
