
import {Component, Prop, Vue} from "vue-property-decorator";
import {KisiSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/KisiSorgulama";

@Component({
  components: {}
})

export default class VekilSorgulaInfo extends Vue {
  @Prop({default: {}}) value!: KisiSorgulamaCevap;

  headers = [
    {text: "TC Kimlik No", value: "tcKimlikNo"},
    {text: "Adı", value: "ad"},
    {text: "Soyadı", value: "soyad"},
    {text: "Avukat ID", value: "avukatId"},
    {text: "Kamu Avukatı Mı", value: "isKamuAvukati"},
    //{text: "Durum", value: "durum"},
  ]
}
