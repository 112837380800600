

import {Component, Vue} from "vue-property-decorator";
import VekilSorgulaForm from "@/components/forms/VekilSorgulaForm.vue";
import VekilSorgulaInfo from "@/components/infos/VekilSorgulaInfo.vue";
import {AvukatSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/AvukatSorgulama";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, VekilSorgulaInfo, VekilSorgulaForm}
})
export default class VekilSorgulaView extends Vue {
  cevap :AvukatSorgulamaCevap = {
    ad: "",
    soyad: "",
    avukatId: 0,
    tcKimlikNo: 0,
    isKamuAvukati: false,
    durum: 0
  };
  success(data:AvukatSorgulamaCevap){
    this.cevap = data
  }
}
